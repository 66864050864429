import axios from 'axios'

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
})

//Global catch error handler
api.interceptors.response.use((response) => response, async (error) => {
    const token = window.sessionStorage.getItem('auth_token_default')

    let ip_client = 'unknown'
    await axios
        .get('https://api.ipify.org?format=json')
        .then((success) => {
            ip_client = success.data.ip
        })


    if(![401, 403].includes(error.response.status)) {
        axios.post(`${process.env.VUE_APP_API_URL}/api/v1/elastic_search/log`, {
            error: error ?? "empty",
            message: error.response ?? "empty",
            ip_client: ip_client
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    }

    //Catch expired token for /auth/me request
    if ((error.response.data.status === 498 || error.response.data.status === 403) && error.response.request.responseURL.includes('auth/me')) {
        document.dispatchEvent(new Event('tokenExpired'))
    }

    //Throw error for other request errors
    throw error
});

export default api